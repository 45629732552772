/*! Text and Header Formatting */
@use "./var" as *;
@use "../mixins/header-presets" as header-presets;
@use "../extra/super-large-header";

$default-section-margin: 1.6rem;
$default-article-margin: 0.8rem;
$default-paragraph-margin: 1rem;

html {
	font-family: "Inter", Georgia, Verdana, monospace;
}

section {
	margin: $default-section-margin;
}

article {
	margin-bottom: $default-article-margin;
}

#docs-page-header + article > :first-child {
	margin-top: 1rem;
}

.text-page-content-container {
	display: flex;
	flex-grow: 1;
	align-content: center;
	justify-content: center;
}

p,
em,
strong,
strike,
input,
textarea,
image {
	& + p,
	& + em,
	& + strong,
	& + strike,
	& + input,
	& + textarea,
	& + image {
		margin: $default-paragraph-margin 0 0 0;
	}
}

h1,
.h1 {
	@include header-presets.h1;
}

h2,
.h2 {
	@include header-presets.h2;
}

h3,
.h3 {
	@include header-presets.h3;
}

h4,
.h4 {
	@include header-presets.h4;
}

h5,
.h5 {
	@include header-presets.h5;
}

h6,
.h6 {
	@include header-presets.h6;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	@include header-presets.h-display;

	div &,
	section &,
	article &,
	li &,
	.important &,
	.red-highlight-text & {
		margin-top: 1.2em;
	}

	.markdown-content div &:first-child {
		margin-top: 0;
	}

	.red-highlight-text &,
	.important & {
		.markdown-content &:first-child,
		article &:first-child,
		section &:first-child {
			margin-top: 0;
		}
	}
}

/* Block the CSS from applying text link formatting on buttons and docs headers */
:not(.sidebar-nav-header-list-item) {
	& > a:not(.button, .button-dark, .docs-button, .inverted-button, .underline-button) {
		&,
		&:link,
		&:active,
		&:visited {
			color: $scheme-dark-primary;
			text-decoration: none;
			background-color: transparent;
		}

		&:hover {
			color: $scheme-primary;
			text-decoration: underline;
		}
	}
}

p {
	margin: 0;
}

/* Dynamic screen size definitions */
.text-content {
	margin: 2rem;
	max-width: 100vw;

	@media screen and (min-width: 1200px) {
		& {
			width: 66.6vw;
			margin-top: 4rem;
			margin-bottom: 4rem;
		}
	}
}

.link-on-red-background {
	&,
	&:visited,
	&:active {
		color: white !important;
		text-decoration: none;
	}

	&:hover {
		color: white !important;
		text-decoration: underline;
	}
}

/* Pure Text Content / Explanatory content */
.header-text-content {
	margin-bottom: 2rem;
}

.shadowed-box {
	border-radius: $default-border-radius;
	box-shadow: 0 0 0.5em $scheme-shadow-gray;
}

.gray-text {
	color: $scheme-dark-gray;
}

.red-text {
	color: $scheme-primary;
}

.yellow-text {
	color: $scheme-secondary;
}

.white-text {
	color: white;
}

.black-text {
	color: black;
}

.centered-text {
	text-align: center;
}

.red-highlight-text,
.important {
	/* Important is a simplified alias */
	border-radius: $default-border-radius;
	margin-right: $default-text-margin;
	border: 1px solid;
	border-color: $scheme-primary;
	color: $scheme-primary;
	padding: 2rem;
	margin-top: 1rem;
	margin-bottom: 1rem;
	width: calc(100% - 4rem - 2px); /* Full-width - 2 * padding - 2 * border */

	& a {
		&:link,
		&:active,
		&:visited {
			/* Add additional emphasis, as red on red text is not highlighted well! */
			color: red;
		}
	}
}

/* Code Block Formatting */
code {
	margin-left: 0.2rem;
	margin-right: 0.2rem;
	padding: 0.2em 0.4em;
	font-size: 85%;
	font-family: Consolas, "Operator Mono", "Fira code.console-bash", Monaco, "Andale Mono", "Ubuntu Mono", monospace;
	border-radius: $default-border-radius;
	background-color: $scheme-gray;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	& code {
		/* Prefer Inter over monospace in headers */
		font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", "Inter", monospace;
	}
}

pre[class*="language-"] {
	margin: 1em 0;
	padding: 1em;
	overflow: auto;
	border-radius: $default-border-radius;
	background-color: $scheme-gray;

	& code {
		margin: 0;
		padding: 0;
		border-radius: 0;
		background: 0 0;
	}
}

.code-toolbar > .toolbar {
	.copy-to-clipboard-button:hover {
		cursor: pointer;
	}
}

/* Overwrite unfitting coloring */
.language-css .token.string,
.style .token.string,
.token.entity,
.token.operator,
.token.url {
	color: #9a6e3a !important;
	background: transparent !important;
}

.green-checkmark {
	text-emphasis: none;
	color: green;
}

.red-checkmark {
	text-emphasis: none;
	color: $scheme-primary;
}

/* Shadowed text content should not take up 100% of the space - slight margins needed */
@media screen and (min-width: $medium-screen-size) {
	.text-content {
		max-width: 92vw;
	}
}

/* Make shadow boxes better viewable on phones */
@media screen and (max-width: $medium-screen-size) {
	.shadowed-box {
		margin: 0;
		padding: 0;
		border-radius: 0;
		box-shadow: none;
	}
}
